
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";

import {ImageModal, ImageZoom} from "../../../shared";
import {
	GET_DELETE_RESPONSE_MODAL,
	GET_SELECTED_ANSWER,
} from "../../../graphql/queries/client-queries";
import {ADD_REEL} from "../../../graphql/mutations/reel-mutations";
import {CREATE_CLIPS} from "../../../graphql/mutations/clip-mutations";
import {AddReelModal} from "../../../modals/add-reel";
import {AnswerPageData, AnswersPageQueryParams, DeleteAnswersReturn, DeleteAnswersVars} from "../../../models/answer";
import {BulkActionsBar} from "../../components/bulk-actions-bar";
import {DELETE_ANSWERS} from "../../../graphql/mutations/results-mutations";
import {HighlightContextProvider} from "../../../context/highlight-context";
import {DeleteMultipleAnswerModal} from "../../../modals/delete-multiple-answer";
import {DeleteResponseModal} from "../../../modals/delete-response";
import {CreateModalContext} from "../../../context/create-modal-context";
import {ResponseContextProvider} from "../../../context/response-context";
import {determineQuestionType, GetSensemakeQueryReturn, QuestionData, QuestionType} from "../../../models/questions";
import {QUESTION_TYPES} from "../../../shared/constants/constants";
import {ChartDisplay} from "../../components/chart-display";
import {useSelectContext} from "../../../context/select-context";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";
import {TranscriptModal} from "../../../modals/transcript-modal";
import {VideoCardList} from "../../components/video-card-list";
import {toggleDeleteResponseModal} from "../../../cache";
import {updateFromDeletion} from "../../../shared/utility/update-response-page";
import {useFilter, useNavigate, useParams, useSearchParams} from "../../../route";
import {SurveyParams} from "../../";
import {useLoadingQuery, useSearchParamState} from "../../../hooks";
import {GET_QUESTION, GET_ALL_ANSWERS, GET_AUTO_REEL} from "../../../graphql/queries/survey-queries";
import {AddReelVars, CreatedReel, ReelVideoStatus} from "../../../models/reels";
import {DirtyReelContext} from "../../../context/dirty-reel-context";

import {Card} from "../../../shared/layout/card";
import {AutoReelInfo} from "../../components/auto-reel-info";
import {SearchAndSort} from "../../components/search-and-sort";
import classNames from "classnames";
import styles from "./survey-response.module.scss";
import {CreateClipsReturn, CreateClipsVars} from "../../../models/clip";
import {TranscriptModalContextProvider} from "../../../context/transcript-modal-context";
import {AnswerTable} from "../../components/answer-table";
import {AddListModal} from "../../../modals/add-list";
import {CopyAnswerModal} from "../../../survey/modals/copy-answer";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {AddPropertyModal} from "../../../contacts/modals/add-property";
import {ADD_ATTRIBUTE_TO_USERS} from "../../../graphql/mutations/mutations";
import {AddAttributeToUserVars, RuleAction} from "../../../models/attribute";

const SurveyResponsePage = (): ReactElement => {
	const {plan, questions} = useContext(SurveyContext);
	const {questionId = questions[0]?.id} = useParams<SurveyParams>();
	const {updateToast} = useContext(ToastContext);
	const {
		isShowingReelModal,
		setIsShowingReelModal,
		singleAnswerId,
		setSingleAnswerId,
		showAddList,
		setShowAddList,
		copyAnswerId,
		setCopyAnswerId,
	} = useContext(CreateModalContext);
	const {setDirtyReel} = useContext(DirtyReelContext);
	const {selected, bulkAdd, emptySelectValues} = useSelectContext();
	const navigate = useNavigate();
	const {membersFilter} = useFilter();
	const resultsFilter = useSearchParams();
	const {workspace: {id: workspaceId}} = useWorkspaceContext();

	const [imageModalShowing, setImageModalShowing] = useState(false);
	const [isShowingDelete, setIsShowingDelete] = useState(false);
	const [highlightsOnly, setHighlightsOnly] = useState(false);
	const [showAddPropModal, setShowAddPropModal] = useState(false);
	const [selectedId, setSelectedId] = useSearchParamState("answerId", "");

	const {data: questionData, fragment: questionFragment} = useLoadingQuery<QuestionData>(GET_QUESTION, {
		variables: {id: questionId, filter: membersFilter},
		skip: !questionId,
		fetchPolicy: "no-cache",
	});
	const question = questionData?.question;

	const updateFilter = (newFilter: Partial<AnswersPageQueryParams>): void => {
		navigate({search: newFilter}, {search: true});
	};

	/**
	 * We skip this query when we do not need the bottom portion of the results.
	 * May change later (like if we do insights based on questions that do not have video)
	 */
	const {data: answerData, fragment, fetchMoreFragment, handleFetchMore} =
	useLoadingQuery<AnswerPageData>(GET_ALL_ANSWERS, {
		notifyOnNetworkStatusChange: true,
		skip: !questionId || !(questionData?.question.videoResponse
			|| questionData?.question.type === QuestionType.TEXT
			|| questionData?.question.type === QuestionType.PICTURE
			|| questionData?.question.type === QuestionType.BARCODE),
		errorPolicy: "all",
		what: "answers",
		display: "vertical",
		fetchPolicy: "network-only",
		variables: {
			questionId,
			filter: {
				...membersFilter,
				highlighted: resultsFilter.highlighted,
				search: resultsFilter.search ? String(resultsFilter.search): undefined,
				textAndTranscriptsSearch: resultsFilter.textAndTranscriptsSearch ? String(resultsFilter.textAndTranscriptsSearch): undefined,
				choiceId: resultsFilter.choiceId?.length ? resultsFilter.choiceId : undefined,
				numberAnswer: resultsFilter.numberAnswer?.length ? resultsFilter.numberAnswer : undefined,
			},
			limit: 20,
			sort: resultsFilter.sort || "MOST_LIKES",
			highlighted: resultsFilter.display === "list" ? resultsFilter.highlighted : undefined,
		},
	});

	/**
	 * On this page we want to query for the auto reel info at the same time.
	 * It takes longer than the GET_QUESTION query so we do it separately.
	 * If there is NO video, it doesn't make sense to query for this.
	 */
	const {data: senseMakeData, fragment: senseMakeFrag} =
	useLoadingQuery<GetSensemakeQueryReturn>(GET_AUTO_REEL, {
		skip: !questionId,
		variables: {id: questionId},
		what: "sensemake data",
	});

	const [search, setSearch] = useState(resultsFilter.search || resultsFilter.textAndTranscriptsSearch);
	const [allSelected, baseSetAllSelected] = useState<boolean>(false);


	const setAllSelectedFalse = (): void => {
		baseSetAllSelected(false);
	}

	const setAllSelected = (v: boolean): void => {
		baseSetAllSelected(v);
		// if selecting all empty selected list
		if (v) {
			emptySelectValues();
		}
	};

	/**
	 * Processes when a single video is disselected when selectAll is true
	 */
	const disselectOneFromSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
		// add everything but the disselected value to the selected list
		bulkAdd(
			answerData?.answers?.items
				?.filter(answer => `${answer.id}_${answer.userId}` !== e.target.value)
				?.map(
					(answer): string => `${answer.id}_${answer.userId}`,
				) || [],
		);
		// turn off selectAll flag
		setAllSelected(false);
	};

	const [deleteAnswersMutation] = useMutation<DeleteAnswersReturn, DeleteAnswersVars>(DELETE_ANSWERS, {
		onCompleted: () => updateToast({
			description: "Response deleted",
			type: "informational",
		}),
	});

	const [addProperty, {loading: addPropLoading}] =
		useMutation<{id: string}, AddAttributeToUserVars>(ADD_ATTRIBUTE_TO_USERS);

	const [createReel] = useMutation<CreatedReel, AddReelVars>(ADD_REEL);
	const [createClips] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS, {
		onCompleted: x => {
			updateToast({
				description: "Clip added to reel",
				type: "informational",
			});
			emptySelectValues();
			const reel = x.createClips[0].reel;
			if (reel && reel?.videoStatus === ReelVideoStatus.DIRTY) {
				setDirtyReel(reel);
			}
		},
	});
	// Query to get answer selected from list so we can view transcript modal
	const answer = useQuery(GET_SELECTED_ANSWER);
	const {selectedAnswer} = answer.data;

	// Query to see if delete modal is being shown.
	const deleteModal = useQuery(GET_DELETE_RESPONSE_MODAL);
	const {toggleDeleteResponseModal: deleteResponseModal} = deleteModal.data;

	const filter = useMemo(() => (
		{
			...membersFilter,
			ageRange: membersFilter.ageRange ? [membersFilter.ageRange] : undefined,
			highlighted: resultsFilter.highlighted,
			search: resultsFilter.search,
			questionId,
			hasVideo: true,
			textAndTranscriptsSearch: resultsFilter.textAndTranscriptsSearch,
			choiceId: resultsFilter.choiceId?.length ? resultsFilter.choiceId : undefined,
			numberAnswer: resultsFilter.numberAnswer?.length ? resultsFilter.numberAnswer : undefined,
		}
	), [membersFilter, resultsFilter, questionId]);

	/**
	 * Turns off the transcript modal by removing the answerId param from the url.
	 */
	const toggleTranscript = (): void => setSelectedId("");
	const toggleAddPropertyModal = (): void => setShowAddPropModal(prev => !prev);

	/**
	 * Hides the delete modal for selected answer
	 */
	const toggleDeleteModal = (): void => {
		toggleDeleteResponseModal(false);
	};
	const handleCloseDelete = (): void => setIsShowingDelete(false);

	/**
	 * Handles changing the select box to select a new filter.
	 * @param newValue New sort value to push into the url
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleFilterSelect = (newSort: any): void => updateFilter({sort: newSort});

	/**
	 * Calls our createClip mutation and sends the clip to specified reel.
	 * @param reelId ReelID we are sending clip to
	 * @param ansId AnswerID of the associated clip.
	 */
	const handleCreateClip = (reelId: string, answerId: string): void => {
		createClips({
			variables: {reelId, input: {answerId}},
		});
	};

	/**
	 * Handles deletion of an answer based on its ID.
	 */
	const handleDeleteAnswer = (): void => {
		deleteAnswersMutation({
			variables: {ids: selectedAnswer},
			update(cache, {data}) {
				if (!data) return;
				const {deleteAnswers} = data;
				updateFromDeletion(cache, deleteAnswers[0].id);
			},
		});
		toggleDeleteResponseModal(false);
	};

	const handleViewHighlightsCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {target: {checked}} = event;

		updateFilter({
			highlighted: checked ? true : undefined,
			display: checked ? "list" : undefined,
		});
		setHighlightsOnly(checked);
	};

	const handleSearch = (newSearch: string): void => updateFilter({display: "list", search: newSearch});
	const handleTextAndTranscriptsSearch = (textAndTranscriptsSearch: string): void => updateFilter({display: "list", textAndTranscriptsSearch});

	const handleSearches = (newSearch: string): void => {
		if (!question) return;
		if (question.type === QuestionType.TEXT || (question.type === QuestionType.PICTURE && question.subtype !== "video")) return handleTextAndTranscriptsSearch(newSearch);
		return handleSearch(newSearch);
	};

	const manualFetchMore = (): void => {
		handleFetchMore(20);
	};

	const handleCreateClips = (reelId: string): void => {
		const vals = selected.map(v => {
			if (v.includes("_")) { // ANSID_USERID if true
				const [x] = v.split("_");
				return {answerId: x};
			}
			return {answerId: v};
		});
		createClips({
			variables: {
				reelId,
				input: vals,
				filter,
				useFilter: allSelected,
			},
			onError: () => {
				updateToast({
					description: "An error occurred while adding clips to reel. Please contact Vurvey support.",
					type: "failure",
				});
			}
		});
	};

	const handleCreateAndSave = (name: string, description?: string): void => {
		createReel({
			variables: {input: {name, workspaceId: workspaceId, description}},
			onCompleted: data => {
				if (!data.createReel) return;
				setIsShowingReelModal(false);
				if ((selected.length > 0) || allSelected) {
					handleCreateClips(data.createReel.id);
				} else if (singleAnswerId) {
					handleCreateClip(data.createReel.id, singleAnswerId);
					setSingleAnswerId("");
				}
			},
			onError: () => updateToast({description: "An error occurred, try again later", type: "failure"}),
		});
	};

	const handleAddProperty = (id: string, ruleAction: RuleAction, value?: string): void => {
		const vals = selected.map(value => {
			if (value.includes("_")) {
				const [, x] = value.split("_");
				return x;
			}
			return value;
		});
		const userIds = [...new Set(vals)];
		addProperty({
			variables: {attributeId: id, userIds, value, ruleAction},
			onCompleted: () => {
				updateToast({
					description: "Property added to user",
					type: "informational",
				});
				setShowAddPropModal(false);
				emptySelectValues();
			},
			onError: () => {
				updateToast({
					description: "Failed to add property",
					type: "failure",
				});
				setShowAddPropModal(false);
				emptySelectValues();
			}
		});
	};

	const handleClearSearch = (): void => {
		updateFilter({
			search: undefined,
			textAndTranscriptsSearch: undefined,
			display: resultsFilter.highlighted ? "list" : undefined,
		});
		setSearch("");
	};
	/**
	 * The bottom of the page has gotten a tad ridiculous with the addition
	 * of reels. This is mostly just to take some of the logic out of the
	 * return statement.
	 */
	const renderBottomCard = (): JSX.Element => {
		// We know question and answerData are defined if we are here, but need to check.
		if (!(question && answerData)) return <div>There is no question selected, somehow</div>;
		const showCards = question.type === QuestionType.CHOICE || question.type === QuestionType.NONE
		|| question.type === QuestionType.SLIDER || question.subtype === "video";
		return (
			<>
				<SearchAndSort
					handleClearSearch={handleClearSearch}
					handleFilter={handleFilterSelect}
					handleHighlightsCheckbox={handleViewHighlightsCheckbox}
					handleSearch={handleSearches}
					highlightState={highlightsOnly}
					searchState={[search, setSearch]}
					selectAllState={[allSelected, setAllSelected]}
					question={question}
					resultsFilter={resultsFilter}
					answerData={answerData}
				/>
				{showCards ? <VideoCardList
					answers={answerData?.answers}
					loading={false}
					resultsFilter={resultsFilter}
					workspacePlan={plan}
					display={resultsFilter.display}
					addClip={handleCreateClip}
					selectAll={allSelected}
					disselectOneFromSelectAll={disselectOneFromSelectAll}
				/> : <AnswerTable
					question={question}
					answers={answerData?.answers}
					addClip={handleCreateClip}
					searchValue={resultsFilter?.search || resultsFilter?.textAndTranscriptsSearch}
				/>
				}
				{answerData.answers.remaining > 0 && !fetchMoreFragment &&
					<div className={styles.fetch}>
						<span onClick={manualFetchMore}>Load more ({answerData?.answers.remaining} remaining)</span>
					</div>
				}
			</>
		);
	};

	if (questionFragment) return questionFragment;
	if (!question) return <></>;

	return (
		<ResponseContextProvider questionId={questionId}>
			<TranscriptModalContextProvider answerId={selectedId} setAnswerId={setSelectedId}>
				<HighlightContextProvider>
					<Card className={styles.response}>
						<p className={styles.type}>
							{QUESTION_TYPES[determineQuestionType(question)].text}
						</p>
						<header className={styles["header-container"]}>
							<h3 className={styles.header}>
								{question.text}
							</h3>
							{
								question.image && question.image.small ?
									<ImageZoom
										image={question.image.small}
										onClick={() => setImageModalShowing(prev => !prev)}
									/> : question.arModel ? <div>
										<model-viewer
											src={question.arModel.glbUrl}
											ios-src={question.arModel.usdzUrl}
											ar
											camera-controls
											alt="A 3D model"
										/>
									</div> : question.video ?
										<div
											className={styles.videoContainer}
											onClick={() => setImageModalShowing(true)}
										>
											<img src={question.video.small}/>
											<div className={styles.play}>
												<div className={styles.arrow}/>
											</div>
										</div>
										: undefined
							}
						</header>
						<ChartDisplay
							question={question}
							changeHistorySearch={updateFilter}
							resultsFilter={resultsFilter as AnswersPageQueryParams}
						/>
						{question.answerCount === 0 &&
							<h2 className={styles.header}>There are currently no responses</h2>
						}
					</Card>
					{/* TODO: check what's wrong with fragments */}
					{answerData ? // Check if there are answers
						(question.type === QuestionType.TEXT || question.type === QuestionType.PICTURE
							|| question.videoResponse || question.type === QuestionType.BARCODE) &&
						<Card className={classNames(styles.response, styles.spacing)}>
							{question.followUp && <h3 className={styles.followUp}>{question.followUp}</h3>}
							<AutoReelInfo
								id={questionId}
								className={styles.autoReel}
								senseData={senseMakeData}
								senseFragment={senseMakeFrag}
								answerTable={renderBottomCard()}
							/>
							<AddReelModal
								isOpen={isShowingReelModal}
								workspaceId={workspaceId}
								handleSave={handleCreateAndSave}
								onClose={() => setIsShowingReelModal(false)}
							/>
							<AddListModal
								isOpen={showAddList}
								onClose={() => setShowAddList(false)}
							/>
							<AddPropertyModal
								isOpen={showAddPropModal}
								handleSave={handleAddProperty}
								loading={addPropLoading}
								onClose={toggleAddPropertyModal}
							/>
							<BulkActionsBar
								workspaceId={workspaceId}
								setIsShowingDelete={setIsShowingDelete}
								allSelected={allSelected}
								addProperty={toggleAddPropertyModal}
								totalItems={answerData.answers.items.length + answerData.answers.remaining}
								filter={filter}
								onClearSelection={setAllSelectedFalse}
							/>
							<DeleteMultipleAnswerModal isOpen={isShowingDelete} onClose={handleCloseDelete} />
							{fetchMoreFragment}
						</Card> : fragment
					}
					{selectedId && (
						<TranscriptModal
							answerId={selectedId}
							onClose={toggleTranscript}
						/>
					)}
					<DeleteResponseModal
						isOpen={deleteResponseModal}
						onClose={toggleDeleteModal}
						confirm={handleDeleteAnswer}
					/>
					<CopyAnswerModal
						isOpen={Boolean(copyAnswerId)}
						onClose={() => setCopyAnswerId("")}
						answerId={copyAnswerId}
					/>
					{(question.image || question.video) && (
						<ImageModal
							image={question.image?.full || question.video?.video}
							isShowing={imageModalShowing}
							isVideo={Boolean(question.video)}
							handleClose={() => setImageModalShowing(prev => !prev)}
						/>
					)}
				</HighlightContextProvider>
			</TranscriptModalContextProvider>
		</ResponseContextProvider>
	);
};

export {SurveyResponsePage};
