import { useState, useEffect } from 'react';

export const useImagePreload = (url?: string) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!url) {
			setIsLoaded(false);
			setError(false);
			return;
		}

		const img = new Image();
		img.src = url;

		img.onload = () => {
			setIsLoaded(true);
			setError(false);
		};

		img.onerror = () => {
			setIsLoaded(false);
			setError(true);
		};

		return () => {
			img.onload = null;
			img.onerror = null;
		};
	}, [url]);

	return { isLoaded, error };
};
