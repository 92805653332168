import React, {createContext, useContext, useEffect, useState} from "react";
import {useSubscription} from "@apollo/client";
import {ORCHESTRATION_EVENTS} from "../../graphql/subscriptions";
import {VurveyOrchestrationEvent, TaskCompleted, TaskOutputGenerated, TaskStarted} from "../../models/subscriptions";
import {useToastContext} from "../toast-context";
import {useParams} from "react-router";
import {useWorkflowContext} from "./workflow-context";
import {useCarouselScrollContext} from "./carousel-scroll-context";
import {useRunWorkflowContext} from "./run-workflow-context";


// eslint-disable-next-line
interface WorkflowEventsContextProps {
	isGeneratingReport: boolean;
}


const WorkflowEventsContext = createContext<WorkflowEventsContextProps | undefined>(undefined);

export const WorkflowEventsProvider = ({children}: { children: React.ReactNode }) => {
	const {updateToast} = useToastContext();
	const {workflowId} = useParams();
	const {setAgentTasks, refetchHistory, setReportUrl} = useWorkflowContext();
	const {scrollToElement} = useCarouselScrollContext();
	const {setIsWorkflowRunning} = useRunWorkflowContext();
	const [isGeneratingReport, setIsGeneratingReport] = useState(false);

	const {data: subscriptionData} = useSubscription(ORCHESTRATION_EVENTS, {
		variables: {orchestrationId: workflowId},
		skip: !workflowId,
	});

	useEffect(() => {
		if (subscriptionData) {
			handleWorkflowEvent(subscriptionData.orchestrationUpdates);
		}
	}, [subscriptionData]);

	const handleWorkflowEvent = (event: VurveyOrchestrationEvent) => {
		switch (event.type) {
		case "OrchestrationStarted": {
			updateToast({description: "Workflow started", type: "informational"});
			break;
		}
		case "OrchestrationCompleted": {
			setIsWorkflowRunning(false);
			setReportUrl(event.data.reportUrl);
			refetchHistory();
			setIsGeneratingReport(false);
			updateToast({
				description: (
					<>
						Workflow completed. You can now download the results at{" "}
						<a href={(event.data as any).reportUrl} target="_blank" rel="noopener noreferrer">
							Report
						</a>
					</>
				),
				type: "success",
				displayTime: 30000,
			});
			break;
		}
		case "OrchestrationError": {
			updateToast({description: `Workflow error: ${event.data.error}`, type: "failure"});
			setIsWorkflowRunning(false);
			setIsGeneratingReport(false);
			refetchHistory();
			break;
		}
		case "TaskOutputGenerated": {
			updateOutputState(event);
			break;
		}
		case "ReportGeneratingStarted": {
			setIsGeneratingReport(true);
			break;
		}
		case "ReportGeneratingCompleted": {
			setIsGeneratingReport(false);
			break;
		}
		case "TaskStarted":
			updateTaskStarted(event);
			break;
		case "TaskCompleted":
			updateTaskCompleted(event);
			break;
		}
	};

	const updateOutputState = (event: TaskOutputGenerated) => {
		setAgentTasks((prevTasks) =>
			prevTasks.map((task) =>
				task.id === event.data.taskId ? {...task, output: event.data.output} : task
			)
		);
	};

	const updateTaskStarted = (event: TaskStarted) => {
		setAgentTasks((prevTasks) =>
			prevTasks.map((task) =>
				task.id === event.data.taskId ? {...task, processingState: "processing"} : task
			)
		);
		scrollToElement(event.data.taskId);
	};

	const updateTaskCompleted = (event: TaskCompleted) => {
		setAgentTasks((prevTasks) =>
			prevTasks.map((task) =>
				task.id === event.data.taskId ? {...task, processingState: "completed"} : task
			)
		);
	};

	return (
		<WorkflowEventsContext.Provider value={{isGeneratingReport}}>
			{children}
		</WorkflowEventsContext.Provider>
	);
};

export const useWorkflowEventsContext = () => {
	const context = useContext(WorkflowEventsContext);
	if (!context) {
		throw new Error("useWorkflowEventsContext must be used within an WorkflowEventsProvider");
	}
	return context;
};
