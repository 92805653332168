import React, {ReactElement, useEffect} from "react";
import {useNavigate} from "react-router";

import {useChatConversationContext} from "../context/chat-contexts";
import {ChatView} from "./chat-view";
import {useWorkspaceContext} from "../context/workspace-context";
import {Campaign, TrainingSet} from "../models/ai-model";
import {Persona} from "../models";

export interface CanvasPageState {
	invoke?: {
		message: string;
		image?: string;
		campaigns: Campaign[];
		trainingSets: TrainingSet[];
		persona?: Persona;
	}
}


export const CanvasPage = (): ReactElement => {
	const navigate = useNavigate();
	const {
		conversation,
	} = useChatConversationContext();
	const {workspace: {chatbotEnabled} = {}} = useWorkspaceContext();

	useEffect(() => {
		if (!chatbotEnabled) {
			navigate("/campaigns")
		}
	}, [chatbotEnabled])

	useEffect(() => {
		document.title = `Vurvey - ${conversation?.name ?? "Conversation"}`;
	}, [conversation?.name])

	return <ChatView />
};
