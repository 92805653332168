import React, {ReactElement, useEffect, useState} from "react";
import classNames from "classnames/bind";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {ArrowSplitIcon, ChevronDownIcon} from "../../../icons";
import {Body} from "../../../shared/v2";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {useThemeMode} from "../../../context/theme-mode-context";
import LinkRenderer from "../../../canvas/chat/response-bubble/content/link-reader";
import {NoDraggableItem} from "../no-draggable-item";
import {ChatScrollContextProvider} from "../../../context/chat-contexts/chat-scroll-context";

import styles from "./output-card.module.scss";

const bStyles = classNames.bind(styles);

export interface OutputCardProps {
	agentTask: AiPersonaTask;
}

export const OutputCard = ({agentTask}: OutputCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (agentTask?.output?.length) {
			setIsExpanded(true);
		}
	}, [agentTask?.output]);

	const cardStyles = bStyles("outputWrapper", {
		completed: agentTask?.processingState?.toLowerCase().includes("completed"),
		isDarkMode,
	});

	return (
		<NoDraggableItem className={styles.noDraggableWrapper}>
			<div className={cardStyles}>
				<div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
					<div className={styles.leftSide}>
						<ArrowSplitIcon className={styles.arrowIcon} />
						<Body type="medium">Output</Body>
					</div>

					<ChevronDownIcon className={styles.chevronIcon} />
				</div>

				{isExpanded && agentTask?.output && (
					<div className={styles.content}>
						<Body className={styles.message}>
							<ChatScrollContextProvider>
								<ReactMarkdown
									remarkPlugins={[remarkGfm]}
									components={{
										a: LinkRenderer,
									}}
								>
									{agentTask.output}
								</ReactMarkdown>
							</ChatScrollContextProvider>
						</Body>
					</div>
				)}
			</div>
		</NoDraggableItem>
	);
};
