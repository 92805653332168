import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Body} from "../../../shared/v2";
import {useWorkflowContext, useWorkflowModalsContext} from "../../../context/workflow-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./objective-card.module.scss";

const bStyles = classNames.bind(styles);

export interface ObjectiveCardProps {
  objective?: string;
}

export const ObjectiveCard = ({objective}: ObjectiveCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {setIsEditWorkflowModalOpen} = useWorkflowModalsContext();
	const {isOnHistoryTab} = useWorkflowContext();

	const handleClick = () => {
		if (isOnHistoryTab) return;
		setIsEditWorkflowModalOpen(true);
	}

	return <div
		className={bStyles("container", {disabled: isOnHistoryTab, isDarkMode})}
		onClick={handleClick}
		tabIndex={0}
	>
		<Body className={styles.objective}>{objective}</Body>
	</div>

}
