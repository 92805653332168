import {useMutation} from "@apollo/client";
import React, {ReactElement, ReactNode, createContext, useCallback, useMemo, useState} from "react";

import {generateChatMessageLoading} from "../../canvas/chat/utils/generateChatMessageLoading";
import {generateFakeInputMessage} from "../../canvas/chat/utils/generateFakeInputMessage";
import {CHAT_SEND_QUESTION} from "../../graphql/mutations/ai-mutations";
import {ChatConversationMessage} from "../../models/ai-model";
import {useSearchParams} from "../../route";
import {useToastContext} from "../toast-context";
import {useWorkspaceContext} from "../workspace-context";
import {ChatSendQuestionResponse, SendQuestionOptions, useChatConversationContext} from "./chat-conversation-context";
import {useChatFollowUpsContext} from "./chat-follow-ups-context";
import {useChatImageContext} from "./chat-image-context";
import {useChatMentionsContext} from "./chat-mentions-context";
import {useChatMessagesContext} from "./chat-message-context";
import {useChatPersonaContext} from "./chat-persona-context";
import {useUnsafeAgentCreationContext} from "../agent-creation-context";

export interface ChatSendQuestionContextValue {
	sendQuestion: (question: string, options?: SendQuestionOptions) => Promise<ChatConversationMessage | undefined>;
  isSendingQuestion: boolean;
  isSendingFirstQuestion: boolean;
}

export const ChatSendQuestionContext =
  createContext<ChatSendQuestionContextValue | undefined>(undefined);

export const ChatSendQuestionContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const {updateToast} = useToastContext();
	const {surveyIds} = useSearchParams();
	const {setMessages, addMessage, replaceMessage} = useChatMessagesContext();
	const {getImageBase64, clearImage} = useChatImageContext();
	const {setFollowUps} = useChatFollowUpsContext();
	const {activePersona} = useChatPersonaContext();
	const {workspace: {id: workspaceId} = {}} = useWorkspaceContext();
	const [isSendingFirstQuestion, setIsSendingFirstQuestion] = useState(false);
	const {agent: agentCreationRecord} = useUnsafeAgentCreationContext() || {};

	const {conversation, createConversation} = useChatConversationContext();
	const {mention} = useChatMentionsContext();

	const [chatSendQuestion, {loading: isSendingQuestion}] = useMutation<ChatSendQuestionResponse>(CHAT_SEND_QUESTION);

	const currentAgent = useMemo(() => {
		if (mention) {
			return mention;
		}

		if (activePersona) {
			return activePersona;
		}

		if (agentCreationRecord) {
			return agentCreationRecord;
		}

		return undefined;
	}, [agentCreationRecord, mention, activePersona]);

	const sendQuestion = useCallback(async (
		question: string,
		options?: SendQuestionOptions,
	): Promise<ChatConversationMessage | undefined> => {
		const {conversationInput, sendQuestionInput, createNewConversation} = options || {};
		let chatConversation = conversation;

		const userMessage = generateFakeInputMessage({
			conversationId: conversation?.id,
			content: question,
		});
		const loadingMessage = generateChatMessageLoading({
			persona: currentAgent,
			conversationId: conversation?.id,
			...sendQuestionInput,
		});

		if (!conversation || createNewConversation) {
			setIsSendingFirstQuestion(true);
			chatConversation = await createConversation(conversationInput);
			setMessages([userMessage, loadingMessage]);
		} else {
			addMessage([userMessage, loadingMessage]);
		}

		if (!chatConversation) {
			throw new Error("Failed to create conversation");
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const variables: any = {
			question,
			workspaceId,
			conversationId: chatConversation.id,
			image: await getImageBase64() ?? undefined,
			surveyIds,
			systemAgentId: options?.systemAgentId,
			messageId: options?.messageId,
			...sendQuestionInput,
		};

		clearImage();

		const {data: chatSendQuestionData} = await chatSendQuestion({
			variables,
			onError: () => {
				setMessages(conversation?.messages?.slice()?.sort((a, b) => a.position - b.position) || []);
				updateToast({
					type: "failure",
					description: "There was a problem. Please try again.",
				});
			},
		});

		if (!chatSendQuestionData) {
			return undefined;
		}

		replaceMessage(loadingMessage.id, chatSendQuestionData.chatSendQuestion);
		setFollowUps(chatSendQuestionData?.chatSendQuestion?.suggestedFollowUps || []);
		setIsSendingFirstQuestion(false);

		return chatSendQuestionData.chatSendQuestion;
	}, [
		currentAgent,
		conversation,
		createConversation,
		getImageBase64,
		surveyIds,
		workspaceId,
	]);


	return (
		<ChatSendQuestionContext.Provider value={{sendQuestion, isSendingQuestion, isSendingFirstQuestion}}>
			{children}
		</ChatSendQuestionContext.Provider>
	);
};

export const useChatSendQuestionContext = (): ChatSendQuestionContextValue => {
	const context = React.useContext(ChatSendQuestionContext);

	if (context === undefined) {
		throw new Error(
			"useChatSendQuestionContext must be used within a ChatSendQuestionContextProvider",
		);
	}

	return context;
};
