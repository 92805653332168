import React, {useLayoutEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import classNames from "classnames/bind";

import {AgentTaskContainer} from "../../components/agent-task-container";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Spinner} from "../../../shared/v2";
import {ObjectiveCard} from "../../components/objective-card";
import {SourcesCard} from "../../components/sources-card";
import {FlowOutputCard} from "../flow-output-card";
import {Connector} from "../connector";
import {useWorkflowContext, useCarouselScrollContext, useWorkflowModalsContext, useRunWorkflowContext, useCarouselConstraintsContext} from "../../../context/workflow-contexts";
import {WorkflowCanvasButton} from "../workflow-canvas-button";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./workflow-canvas.module.scss";

const bStyles = classNames.bind(styles);

export interface WorkflowCanvasProps {
	disabled?: boolean;
	agents?: AiPersonaTask[];
}

export const WorkflowCanvas = ({disabled, agents}: WorkflowCanvasProps) => {
	const {isDarkMode} = useThemeMode();
	const {
		workflow,
		agentTasks,
		setAgentTasks,
		currentSources,
		isLoadingWorkflow,
		currentHistory,
		isOnHistoryTab,
		reportUrl,
		currentHistorySources,
	} = useWorkflowContext();

	const {isWorkflowRunning} = useRunWorkflowContext();

	const {setIsSelectAgentModalOpen, setIsSourcesModalOpen} = useWorkflowModalsContext();
	const {
		draggableEl,
		setDraggableEl,
		constraintEl,
		setConstraintEl,
		constraints: carouselConstraints,
	} = useCarouselConstraintsContext();
	const lastElementRef = useRef<HTMLDivElement>(null);

	const {x, y, scrollToElement} = useCarouselScrollContext();

	useLayoutEffect(() => {
		if (agents && lastElementRef.current) {
			scrollToElement(agents[agents.length - 1].id);
		}
	}, [carouselConstraints.left, carouselConstraints.right, currentHistory]);

	const handleTaskChange = (agent: AiPersonaTask, value: string) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					return {...item, operation: "UPDATE", task: {taskPrompt: value}};
				}
				return item;
			}),
		);
	};

	const handleRemoveAgent = agentTask => {
		if (agentTask.id.startsWith("NEW")) {
			setAgentTasks(agentTasks.filter(a => a.id !== agentTask.id));
			return;
		}

		agentTask.operation = "DELETE";
		setAgentTasks(agentTasks.map(item => (item.id === agentTask.id ? agentTask : item)));
	};

	const handleAddAgent = () => {
		setIsSelectAgentModalOpen(true);
	};

	const handleIndexChange = (agent: AiPersonaTask, newIndex: number) => {
		setAgentTasks(
			agentTasks.map(item => {
				if (item.id === agent.id) {
					item = {...item, index: newIndex, operation: "UPDATE"};
					return item;
				}
				return item;
			}),
		);
	};

  const [dragging, setDragging] = useState(false);

	const handleDrag = (event, info) => {
    const { x, y } = info.point;
    if (constraintEl) {
			constraintEl.style.backgroundPosition = `${x}px ${y}px`;
    }
  };


	const handleDragEnd = () => {
		setDragging(false)
	}

	const renderWorkflow = () => {
		if (isLoadingWorkflow) {
			return (
				<div className={styles.loadingContainer}>
					<Spinner className={styles.spinner} />
				</div>
			);
		}
		if (!workflow) {
			return null;
		}

		return (
			<motion.div
				className={bStyles("carouselContent", {dragging})}
			 	drag={true}
				dragConstraints={carouselConstraints}
				onDragStart={() => setDragging(true)}
				onDragEnd={
					handleDragEnd
				}
				onDrag={handleDrag}
				id="workflow-carousel"
				style={{x, y}}

			>
				<div className={styles.carousel} ref={setDraggableEl}>
					{isOnHistoryTab &&
						<SourcesCard
							workflow={workflow}
							openSourcesModal={() => setIsSourcesModalOpen(true)}
							currentSources={currentHistorySources}
							disabled={disabled}
						/>
					}
					{!isOnHistoryTab &&
						<SourcesCard
							workflow={workflow}
							openSourcesModal={() => setIsSourcesModalOpen(true)}
							currentSources={currentSources}
							disabled={disabled}
						/>
					}

					{agents?.map((agent, index) => (
						<AgentTaskContainer
							key={agent.id}
							agentTask={agent}
							onRemove={handleRemoveAgent}
							onChangeTask={value => handleTaskChange(agent, value)}
							onChangeIndex={handleIndexChange}
							disabled={disabled}
							elementRef={index === agents.length - 1 ? lastElementRef : null}
							agentsLength={agents.length}
						/>
					))}

					{!disabled && !isWorkflowRunning &&
						<>
							<Connector />
							<WorkflowCanvasButton
								onClick={handleAddAgent}
								disabled={isWorkflowRunning}
							/>
						</>
					}

					<Connector disabled={disabled} />
					<FlowOutputCard
						reportUrl={isOnHistoryTab ? currentHistory?.reportUrl : reportUrl}
					/>
				</div>
			</motion.div>
		);
	};

	return (
		<div
			className={bStyles("canvas", {isDarkMode})}
			ref={setConstraintEl}
		>
			<ObjectiveCard objective={workflow?.description} />
			{renderWorkflow()}
		</div>
	);
};

