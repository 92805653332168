import React, {ReactElement, useState} from "react";
import {useNavigate} from "react-router";
import classNames from "classnames/bind";

import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {ButtonIcon, Subheader, Button} from "../../../shared/v2";
import {ChevronLeftIcon, CloudSaveUploadIcon, LightingBoltStrikeIcon, PenEditIcon, PlusSmallIcon} from "../../../icons";
import {useRunWorkflowContext} from "../../../context/workflow-contexts/run-workflow-context";
import {useWorkflowContext, useWorkflowModalsContext} from "../../../context/workflow-contexts";
import {ConfirmActionModal} from "../../../shared/v2/modals/confirm-action-modal";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./top-bar.module.scss";

const bStyles = classNames.bind(styles);

export const TopBar = () => {
	const {setIsEditWorkflowModalOpen} = useWorkflowModalsContext();
	const navigate = useNavigate();
	const {isDarkMode} = useThemeMode();
	const {
		workflow,
		isWorkflowDirty,
		handleSaveWorkflow,
		isSaving,
		isOnHistoryTab,
	} = useWorkflowContext();

	const {
		isWorkflowRunning,
		isWorkflowValid,
		handleRunWorkflow,
	} = useRunWorkflowContext();

	const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
	const [isCloneRunModalOpen, setIsCloneRunModalOpen] = useState(false);

	const handleBack = () => {
		if (isWorkflowDirty) {
			return setIsUnsavedChangesModalOpen(true);
		}

		navigate("/workflow/flows");
	};

	const renderButton = (): ReactElement | null => {
		if (isOnHistoryTab) {
			return null;
			// TODO: bring back when cloning is implemented
			// return <Button
			// 	className={styles.actionButton}
			// 	disabled={isSaving}
			// 	onClick={() => setIsCloneRunModalOpen(true)}
			// 	size="small"
			// 	leftIcon={<PlusSmallIcon />}
			// >
			// 	Clone
			// </Button>
		}

		if (isWorkflowDirty) {
			return <Button
				className={styles.actionButton}
				disabled={isSaving}
				onClick={handleSaveWorkflow}
				size="small"
				leftIcon={<CloudSaveUploadIcon />}
			>
				Save
			</Button>
		}
		return <Button
			className={styles.actionButton}
			onClick={handleRunWorkflow}
			disabled={isWorkflowRunning || !isWorkflowValid}
			size="small"
			style="ai"
			leftIcon={<LightingBoltStrikeIcon />}
		>
			Run Workflow
		</Button>
	}

	return (
		<>
			<div className={bStyles("topBar", {isDarkMode})}>
				<div className={styles.leftSide}>
					<ButtonIcon onClick={handleBack} filledIcon icon={<ChevronLeftIcon />} />

					<ButtonGroup>
						<ButtonGroupElement
							size="small"
							text="Build"
							to=""
							end /
						>
						<ButtonGroupElement
							size="small"
							text="History"
							to="history"
						/>
					</ButtonGroup>
				</div>

				<div className={styles.centerSide}>
					{workflow && (
						<>
							<Subheader type="medium">{workflow?.name || ""}</Subheader>
							{!isOnHistoryTab &&
								<ButtonIcon disabled={isWorkflowRunning}  onClick={() => setIsEditWorkflowModalOpen(true)} icon={<PenEditIcon />} />
							}
						</>
					)}
				</div>

				<div className={styles.rightSide}>
					{workflow && (
						<>
							{renderButton()}
						</>
					)}
				</div>
			</div>
			<ConfirmActionModal
				title="Unsaved Changes"
				description="You have unsaved changes in your workflow. Are you sure you want to leave without saving?"
				isOpen={isUnsavedChangesModalOpen}
				onClose={() => setIsUnsavedChangesModalOpen(false)}
				onConfirm={() => {
					navigate("/workflow/flows");
					setIsUnsavedChangesModalOpen(false);
				}}
				confirmText="Exit Without Saving"
				cancelText="Continue Editing"
				size="small"
			/>

			<ConfirmActionModal
				title="Clone Workflow Run"
				description="This run will be copied, creating a new workflow based on it. You can then edit and adjust the workflow as needed."
				isOpen={isCloneRunModalOpen}
				onClose={() => setIsCloneRunModalOpen(false)}
				onConfirm={() => setIsCloneRunModalOpen(false)}
				confirmText="Cancel"
				cancelText="Clone & Create"
				confirmButtonStyle="brand"
				size="x-small"
			/>
		</>
	);
};
