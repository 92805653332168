import {gql} from "@apollo/client";

export const CHAT_CONVERSATION_UPDATES = gql`
  subscription Subscription($conversationId: GUID!) {
    chatConversationUpdates(conversationId: $conversationId) {
      ... on ConversationUserMessageCreatedEvent {
        type
        data {
          questionId
          conversationId
          content
        }
      }

      ... on ConversationResponseCreatedEvent {
        type
        data {
          conversationId
          conversationResponseId
          questionId
          agentId
          chunkPosition
          content
          type
          hasGrounding
          lastChunk
        }
      }

      ... on ConversationResponseStateChangedEvent {
        type
        data {
          conversationId
          conversationResponseId
          content
        }
      }
    }
  }
`;

export const ORCHESTRATION_EVENTS = gql`
  subscription Subscription($orchestrationId: GUID!) {
    orchestrationUpdates(orchestrationId: $orchestrationId) {
      ... on OrchestrationStartedEvent {
        type
        data {
          orchestrationId
        }
      }
      ... on OrchestrationCompletedEvent {
        type
        data {
          orchestrationId
          reportUrl
        }
      }
      ... on OrchestrationErrorEvent {
        type
        data {
          orchestrationId
          error
        }
      }
      ... on TaskStartedEvent {
        type
        data {
          orchestrationId
          taskId
          personaId
          personaName
          processingState
        }
      }
      ... on TaskPromptGeneratedEvent {
        type
        data {
          orchestrationId
          taskId
          prompt
        }
      }
      ... on TaskOutputGeneratedEvent {
        type
        data {
          orchestrationId
          taskId
          output
        }
      }
      ... on TaskCompletedEvent {
        type
        data {
          orchestrationId
          taskId
        }
      }
      ... on ReportGeneratingStartedEvent {
        type
        data {
          orchestrationId
        }
      }
      ... on ReportGeneratingCompletedEvent {
        type
        data {
          orchestrationId
          reportUrl
        }
      }
    }
  }
`;

export const WORKSPACE_EVENTS = gql`
  subscription Subscription($workspaceId: GUID!, $options: WorkspaceUpdatesOptions) {
    workspaceUpdates(workspaceId: $workspaceId, options: $options) {
      ... on WorkspaceRecentConversationsReorderedEvent {
        type
        data {
          workspaceId
          recentConversations {
            id
            name
            isPreview
            createdAt
            workspaceId
          }
        }
      }
    }
  }
`;
